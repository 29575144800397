<template>
  <div v-if="itemList.length > 0" class="py-24 flex-1 relative">
    <h2 class="type-headline-xl mb-24 mobOnly:px-12">{{ title }}</h2>
    <div class="transition-opacity duration-300 opacity-100 desk:layout-container mobOnly:mt-24">
      <div v-if="itemList.length > 6" class="hidden desk:flex justify-between">
        <div class="swiper-button-prev absolute inset-y-0 !top-[45%]" :class="[`swiper-for-blok-recommended`]" />
        <div class="swiper-button-next absolute inset-y-0 !top-[45%]" :class="[`swiper-for-blok-recommended`]" />
      </div>
      <client-only>
        <swiper
          :space-between="isMobile ? 12 : 12"
          :slides-per-view="isMobile ? 2.5 : 6"
          :slides-offset-before="isMobile ? 12 : 0"
          :slides-offset-after="isMobile ? 24 : 0"
          :modules="[Navigation]"
          :navigation="{
            nextEl: `.swiper-button-next.swiper-for-blok-recommended`,
            prevEl: `.swiper-button-prev.swiper-for-blok-recommended`,
          }"
          class="mobOnly:px-24"
        >
          <swiper-slide
            v-for="(product, slideIndex) in itemList"
            :key="product.partNo"
          >
            <ProductCard
              :key="product.partNo + slideIndex"
              v-track="{product, index: slideIndex, pageName: globalContent.currentStory.name, pageId: globalContent.currentStory.id + 1}"
              :product="product"
              :enable-quick-buy="true"
              :point-shop="false"
              :placement="100"
              design="standard"
            />
          </swiper-slide>
        </swiper>
      </client-only>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useUiStore } from '~/store/ui';
import { useGlobalContentStore } from '~/store/globalContent';
import { storeToRefs } from 'pinia';

import ProductCard from '~/components/product/ProductCard.vue';
import Product, { ProductModel } from '~/models/product';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { useProductImpressionTracking as vTrack } from '~/composeables/useTracking';

import 'swiper/css';
import 'swiper/css/navigation';
import { getLocalCurrency } from '~/composeables/useLocalizedCurrency';

const globalContent = useGlobalContentStore();
const uiStore = useUiStore();
const { isMobile } = storeToRefs(uiStore);

const props = defineProps<{
  itemList: ProductModel[],
  title: string,
}>();

const jsonLd = {
  '@context': 'https://schema.org/',
  '@type': 'ItemList',
  numberOfItems: props.itemList.length,
  itemListElement: props.itemList.map(productItem => {
    const activeVariant = productItem?.variants[0];
    return {
      '@type': 'Product',
      url: productItem.url,
      name: productItem.name,
      image: productItem.imageList,
      description: productItem.metaDescription,
      sku: productItem.partNo,
      mpn: productItem.partNo,
      brand: {
        '@type': 'Brand',
        name: productItem.brand.name,
      },
      offers: {
        '@type': 'Offer',
        url: productItem.url,
        priceCurrency: getLocalCurrency(),
        itemCondition: 'https://schema.org/NewCondition',
        availability: activeVariant?.canAddToCart ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock',
      },
    };
  }),
} as any;

useJsonld(jsonLd);
</script>

<style scoped lang="postcss">
.swiper-button-prev {
  left: -15px;
}
.swiper-button-next {
  right: -15px;
}
.swiper-button-disabled {
  display: none;
}
</style>
