<template>
  <div class="ProductDescription relative">
    <div
      v-if="productItem.description"
      ref="productDescription"
      class="wysiwyg type-sm"
      v-html="productItem.description"
    />
    <div
      class="type-base-medium type-sm"
      :class="{
        'mt-12': productItem.description
      }"
    >
      {{ $t('filter.name.brandFilters') }}
      <nuxt-link
        :to="$manualUrl(globalContent.getBrandLink(productItem.brand.code))"
        class="underline block font-normal"
      >
        {{ productItem.brand.name }}
      </nuxt-link>
    </div>
    <div class="type-base-medium type-sm mt-16">
      {{ $t('productPage.partNo') }}
      <span class="font-normal block">{{ activeVariant.partNo }}</span>
    </div>
    <div
      v-if="activeVariant.volume"
      class="type-base-medium type-sm mt-16"
    >
      {{ activeVariant.volume.name }}
      <span class="font-normal block">{{ activeVariant.volume.value }}</span>
    </div>
    <div
      v-if="productItem.characteristics?.multipleValues?.length"
      class="type-base-medium type-sm mt-16"
    >
      {{ productItem.characteristics.name }}
      <div class="flex flex-1 flex-wrap">
        <div
          v-for="item in productItem.characteristics?.multipleValues"
          :key="'product-characteristics-' + item.code"
        >
          <button class="uppercase type-sm text-center p-12 px-20 mr-12 mt-6 whitespace-nowrap bg-lighter pointer-events-none">
            {{ item.name }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ProductModel } from '~/models/product';
import ProductVariant from '~/models/productVariant';
import { useGlobalContentStore } from '~/store/globalContent';
const globalContent = useGlobalContentStore();

defineProps<{
  productItem: ProductModel,
  activeVariant: ProductVariant,
}>();

</script>

<style lang="postcss">

</style>
