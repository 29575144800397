<template>
  <div class="relative z-10 my-12 type-headline-xxs desk:type-headline-xs text-dark flex px-12 desk:px-0 sm:px-0 md:mb-0">
    <div
      class="flex items-center underline decoration-light hover:text-darkest hover:decoration-darkest transition-all"
    >
      <nuxt-link
        :href="$manualUrl(brandlistUrl)"
        class="uppercase hoverdistance"
      >
        {{ globalContent.brandlistPage?.story.name }}
      </nuxt-link>
    </div>
    <span class="px-8 type-xs">/</span>
    <div
      class="flex items-center underline decoration-light hover:text-darkest hover:decoration-darkest transition-all"
    >
      <nuxt-link
        :href="$manualUrl(globalContent.getBrandLink(productItem.brand.code))"
        class="uppercase hoverdistance"
      >
        {{ productItem.brand.name }}
      </nuxt-link>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ProductModel } from '~/models/product';
import { useGlobalContentStore } from '~/store/globalContent';

const globalContent = useGlobalContentStore();

defineProps<{
  productItem: ProductModel,
}>();

const brandlistUrl = globalContent.brandlistPage?.story?.full_slug?.replace(globalContent.getStoryblokBasePath + '/', '') ?? '/';

</script>

<style scoped lang="postcss">

</style>
