<template>
  <div class="ProductImage relative">
    <transition name="fade">
      <ModalVideoPlayer
        v-if="showVideo !== ''"
        :video-file="showVideo"
        @close="closeVideo"
      />
    </transition>
    <div
      v-if="productItem.isNewProduct || activeVariant.isOnSaleForUser"
      class="absolute w-70 h-70 top-0 right-0 mt-16 mr-16 z-40"
    >
      <img
        :src="productItem.isNewProduct
          ? '/splashes/bywe_donut-news.svg'
          : '/splashes/bywe_donut-sale.svg'"
      >
    </div>
    <div
      v-else-if="productItem.isPercentageCampaign || productItem.isStepCampaigns"
      class="absolute w-70 h-70 top-0 right-0 mt-16 mr-16 z-40"
    >
      <img
        :src="`/splashes/buy-more-${globalContent.getLocale}.svg`"
      >
    </div>

    <FullScreenGallery
      v-if="galleryVisible"
      :images="images"
      :start-index="startGalleryIndex"
      :current-image-list="currentImageList"
      :product-item="productItem"
      @close="galleryVisible = false"
    />
    <div
      v-if="images.length > 0"
      class="relative min-h-[400px]"
    >
      <SimpleSwiper
        ref="mainSwiper"
        :images="images"
        :slide-margin="2"
        :slide-width="100"
        sizes="mob375:100vw mob390:100vw mob414:100vw desk:538px"
        slide-classes="cursor-pointer"
        preset="pdpimage"
        :lazy-first-image="false"
        @image-click="onImageClick"
        @set-slide="setSlide"
      />
      <div class="imageOverlay absolute top-0 left-0 w-full h-full pointer-events-none" />
    </div>

    <img
      v-else
      src="/icons/no-image.svg"
      alt="no image"
      class="w-full object-cover"
    >

    <client-only>
      <Teleport to="#ProductImageThumbnails">
        <div class="w-[98px] h-[538px] relative">
          <div
            v-if="images.length > 4"
            class="hidden desk:block absolute w-full h-full"
          >
            <div
              class="swiper-button-prev absolute !top-6 !left-[34px] rotate-90"
            />
            <div
              class="swiper-button-next absolute !-bottom-16 !top-auto !left-[34px] rotate-90"
            />
          </div>

          <swiper
            direction="vertical"

            :space-between="12"
            :slides-per-view="5"
            :slides-offset-before="0"
            :slides-offset-after="0"
            :modules="[Navigation, Mousewheel]"
            :mousewheel="true"
            :navigation="{
              nextEl: `.swiper-button-next`,
              prevEl: `.swiper-button-prev`,
            }"
            class="h-[538px] w-auto"
          >
            <swiper-slide
              v-for="(image, index) in images"
              :key="image.file"
            >
              <div
                class="w-[98px] h-[98px] relative border border-lightest cursor-pointer transition-all"
                :class="{
                  '!border-darkest border-animation': index === thumbSelected
                }"
                @click.prevent="clickThumb(index)"
              >
                <div
                  v-if="image.linkedVideo"
                  class="absolute w-full h-full flex items-center justify-center"
                >
                  <div class="inline-block w-32 h-32 bg-darker flex items-center justify-center">
                    <img src="/icons/play-filled-inv.svg" class="w-16 h-16">
                  </div>
                </div>
                <nuxt-img
                  preset="standard"
                  provider="norce"
                  :src="image.file"
                  sizes="80px"
                  class="w-[97px] h-[97px] max-h-[96px] object-cover"
                />
                <div class="imageOverlay absolute top-0 left-0 w-full h-full pointer-events-none" />
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </Teleport>
    </client-only>
  </div>
</template>

<script lang="ts" setup>

import { useUiStore } from '~/store/ui';
import { ProductModel } from '~/models/product';
import SimpleSwiper from '~/components/globals/SimpleSwiper.vue';
import FullScreenGallery from '~/components/product-page/FullScreenGallery.vue';
import { NorceMediaSetCodes } from '~/constants/norceCodes';
import { ProductVariantModel } from '~/models/productVariant';

import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Mousewheel } from 'swiper';
import { storeToRefs } from 'pinia';
import ModalVideoPlayer from '~/components/modals/ModalVideoPlayer.vue';
import { useGlobalContentStore } from '~/store/globalContent';
import useRouterNormalize from '~/composeables/useRouterNormalize';

const { normalizeQuery } = useRouterNormalize();
const globalContent = useGlobalContentStore();
const route = useRoute();
const props = defineProps<{
  productItem: ProductModel;
  currentImageList: NorceMediaSetCodes;
  activeVariant: ProductVariantModel;
}>();

const uiStore = useUiStore();
const { isMobile } = storeToRefs(uiStore);

const galleryVisible = ref(false);
const startGalleryIndex = ref<number>(0);
// https://bywe.cdn.storm.io/312e17dd-4ace-4caf-9bd7-15309786a40b
const showVideo = ref('');

const onImageClick = (imageIndex: number) => {
  if (images.value[imageIndex].linkedVideo) {
    showVideo.value = images.value[imageIndex].linkedVideo;
    return;
  }

  if (isMobile.value) {
    return;
  }
  startGalleryIndex.value = imageIndex;
  galleryVisible.value = true;
};

const images = computed(()=> {
  if (props.productItem.extendedLayout) {
    return props.productItem.mainProductImage;
  }
  return props.productItem.imagesAndVideo(props.currentImageList, props.activeVariant);
});

const thumbSelected = ref(0);
const mainSwiper = ref<typeof SimpleSwiper | null>(null);

const setSlide = (index: number) => {
  thumbSelected.value = index;
  setSlideUrl(index);
};
const clickThumb = (index: number) => {
  thumbSelected.value = index;
  setSlideUrl(index);
  if (images.value[index].linkedVideo) {
    showVideo.value = images.value[index].linkedVideo;
    return;
  }

  if (mainSwiper.value) {
    mainSwiper.value.goImage(index);
  }
};

const setSlideUrl = (index: number) => {
  history.replaceState(
    {},
    '',
    route.path + '?slide=' + index
  );
};

const isPercentageCampaigns = computed(()=> {
  return props.productItem.displayFlags;
});

onMounted(()=> {
  const slideNo = parseInt(normalizeQuery('slide'));
  if (!isNaN(slideNo) && slideNo >= 0 && slideNo < images.value.length) {
    console.log('show image');
    setTimeout(()=> {
      clickThumb(slideNo);
    }, 200);
  }
});

const closeVideo = () => {
  showVideo.value = '';
  history.replaceState(
    {},
    '',
    route.path
  );
};

</script>

<style lang="postcss">
.ProductImage .SimpleSwiper .imageHolder>img {
  @apply desk:max-h-[560px] object-cover w-full !h-full aspect-square !m-auto;
}
.border-animation {
  transition-delay: 0.2s;
}
</style>
